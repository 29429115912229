<template>
   <section class="cont splitOrder-box">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>业务中台</el-breadcrumb-item>
            <el-breadcrumb-item>对账报表</el-breadcrumb-item>
            <el-breadcrumb-item>营销订单明细</el-breadcrumb-item>
            <el-breadcrumb-item>{{tabState === 'order' ? "分账订单" : "分账明细"}}</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-tabs v-model="tabState" @tab-click="tabClick" class="tab-box" >
            <el-tab-pane label="分账订单" name="order"></el-tab-pane>
            <el-tab-pane label="分账明细" name="detailed"></el-tab-pane>
         </el-tabs>
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item m-right-50">
                  <label>订单编号：</label>
                  <el-input class="width-180" placeholder="请输入订单编号" v-model="orderNumber" clearable></el-input>
               </el-row>
               <el-row class="search-item m-right-50">
                  <label>订单状态：</label>
                  <el-select class="width-180" v-model="orderState" placeholder="请选择" clearable>
                     <el-option
                           v-for="(item,index) in dictData['order-status@splitOrderList']"
                           :key="index"
                           :label="item"
                           :value="index">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item m-right-50">
                  <label>支付时间：</label>
                  <el-date-picker class="width-140" v-model="beginTime" type="date"
                                  placeholder="请选择" value-format="yyyy-MM-dd"></el-date-picker>
                  <span> 至 </span>
                  <el-date-picker class="width-140" v-model="endTime" type="date"
                                  placeholder="请选择" value-format="yyyy-MM-dd"></el-date-picker>
               </el-row>
               <el-row class="search-item m-right-50" v-if="tabState === 'detailed'">
                  <label>分佣人：</label>
                  <el-input  class="width-180" placeholder="请输入分佣人" v-model="deptPerson" clearable></el-input>
               </el-row>
            </el-row>
            <el-row class="search-row">
               <el-row class="search-item m-right-50" v-if="tabState === 'order'">
                  <label>订单类型：</label>
                  <el-select  class="width-180" v-model="orderType" placeholder="请选择" clearable>
                     <el-option
                           v-for="(item,index) in dictData['order-type']"
                           :key="index"
                           :label="item"
                           :value="index">
                     </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item m-right-50">
                  <label>分账状态：</label>
                  <el-select  class="width-180" v-model="state" placeholder="请选择" clearable v-if="tabState === 'order'">
                     <el-option
                           v-for="(item,index) in dictData['sub-account']"
                           :key="index"
                           :label="item"
                           :value="index">
                     </el-option>
                  </el-select>
                  <el-select  class="width-180" v-model="state" placeholder="请选择" clearable v-if="tabState === 'detailed'">
                      <el-option
                      v-for="(item,index) in dictData['payment-state']"
                      :key="index"
                      :label="item"
                      :value="index">
                      </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item m-right-50"  v-if="tabState === 'detailed'">
                  <label>分佣类型：</label>
                  <el-select class="width-180" v-model="deptLevel" placeholder="请选择" clearable>
                       <el-option
                      v-for="(item,index) in dictData['marketing-level']"
                      :key="index"
                      :label="item"
                      :value="index">
                      </el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item m-right-50" v-if="tabState === 'detailed'">
                  <label>到账时间：</label>
                  <el-date-picker class="width-140" v-model="arrivalBeginTime" type="date"
                                  placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                  <span> 至 </span>
                  <el-date-picker class="width-140" v-model="arrivalEndTime" type="date"
                                  placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
               </el-row>
              <HotelSelect :storeIdQ="storeId"  v-if="selectshow" @setstoreId="getDate"> </HotelSelect>
               <el-button class="bg-gradient" @click="queryEvent">搜索</el-button>
               <el-button class="bg-gradient" @click="resetEvent">重置</el-button>
               <el-button class="bg-gradient" type="primary"  @click="exportEvent">导出</el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{background: '#f5f8f9'}">
               <el-table-column label="序号" type="index" width="55" key="index"></el-table-column>
               <el-table-column label="购买订单编号" prop="orderNo" width="210" key="orderNo">
                  <template slot-scope="scope">
                     <span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;">{{scope.row.orderNo}}</span>
                  </template>
               </el-table-column>
<!--               <el-table-column label="分销订单号" min-width="85" prop="userId"></el-table-column>-->
               <el-table-column label="订单类型" width="100" key="orderType">
                  <template slot-scope="scope">
                     <span> {{ scope.row.orderType | filterOrderType(that) }}</span>
                  </template>
               </el-table-column>
               <template v-if="tabState === 'order'">
                  <el-table-column label="客户姓名" prop="userName" width="145" key="userName"></el-table-column>
                  <el-table-column label="客户电话" prop="mobile" width="120" key="mobile"></el-table-column>
                  <el-table-column label="支付金额" prop="actualAmount" width="100" key="actualAmount"></el-table-column>
                  <el-table-column label="分佣金额" prop="commissionAmount" width="100" key="commissionAmount"></el-table-column>
               </template>
               <template v-if="tabState === 'detailed'">
                  <el-table-column label="分佣商品" prop="productName" width="150" key="productName"></el-table-column>
                  <el-table-column label="交易金额" prop="actualAmount" width="100" key="actualAmount"></el-table-column>
                  <el-table-column label="营销规则" prop="marketName" width="220" key="marketName"></el-table-column>
                  <el-table-column label="分佣类型" prop="level" width="100" key="level">
                     <template slot-scope="scope">
                        <span>{{scope.row.level | filterMarketing(that)}}</span>
                     </template>
                  </el-table-column>
                  <el-table-column label="分佣人姓名" prop="memberName" width="150" key="memberName"></el-table-column>
                  <el-table-column label="分佣人电话" prop="mobile" width="120" key="mobile"></el-table-column>
                  <el-table-column label="分佣金额" prop="awardAmount" width="100" key="awardAmount"></el-table-column>
               </template>


               <el-table-column label="订单状态" width="100" key="orderStatus">
                  <template slot-scope="scope">
                     <span> {{ scope.row.orderStatus | filterOrderStatus(that) }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="支付时间" width="170" key="payTime">
                  <template slot-scope="scope" v-if="scope.row.payTime">
                     <el-row>{{scope.row.payTime}}</el-row>
                  </template>
               </el-table-column>
               <el-table-column label="分账状态" width="110" key="subAccountState">
                  <template slot-scope="scope">
                     <span v-if="tabState === 'order'">{{ scope.row.subAccountState| filterPaymentOrder(that) }}</span>
                     <span v-if="tabState === 'detailed'">{{ scope.row.state | filterPaymentDetailed(that) }}</span>
                  </template>
               </el-table-column>
<!--               <el-table-column label="备注">-->
<!--                  <template slot-scope="scope">-->
<!--                     <span>{{ scope.row.id }}</span>-->
<!--                  </template>-->
<!--               </el-table-column>-->
               <el-table-column label="到账时间" width="170">
                  <template slot-scope="scope" v-if="scope.row.accountTime">
                     <el-row>{{scope.row.accountTime}}</el-row>
                  </template>
               </el-table-column>
               <el-table-column label="操作" width="120" fixed="right" v-if="tabState === 'order'">
                  <template slot-scope="scope">
                     <el-button type="text" v-if="tabState === 'order'">
                        <el-link type="primary" @click="handleCheck(scope.row,scope.$index)">查看详情</el-link>
                     </el-button>
<!--                     <el-button type="text" v-if="tabState === 'detailed' ? scope.row.state === 'FAIL' ? true : false :false ">-->
<!--                        <el-link type="primary"  @click="handleAccount(true,scope.row)">重新分账</el-link>-->
<!--                     </el-button>-->
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" v-if="pageState"/>
         </el-row>
      </el-row>
      <!-- 分佣弹窗 -->
      <el-dialog title="分佣明细" :visible.sync="isShow" width="70%" :before-close="handleClose" v-if="isShow">
         <el-row class="split-dialog-box">
            <ul>
               <li>订单编号：{{ rowData.orderNo }}</li>
               <li>客户姓名：{{ rowData.userName }}</li>
               <li>客户电话：{{ rowData.mobile }}</li>
            </ul>
            <ul>
               <li>支付时间：{{ rowData.payTime }}</li>
               <li>分佣金额：{{ rowData.commissionAmount }}</li>
            </ul>
         </el-row>
         <el-row style="margin-bottom: 20px;">
            <el-table
                  v-loading="detailedLoading"
                  :data="detailedTable"
                  border fit
                  style="width: 100%"
                  :stripe="true"
                  :header-cell-style="{background: '#f5f8f9'}">
               <el-table-column label="序号" prop="checkIndex" width="55"></el-table-column>
               <el-table-column label="分佣商品" prop="productName" min-width="130"></el-table-column>
               <el-table-column label="交易金额" prop="actualAmount" width="100"></el-table-column>
               <el-table-column label="营销规则" prop="marketName" width="100"></el-table-column>
               <el-table-column label="分佣类型" prop="level" width="100">
                  <template slot-scope="scope">
                     <span>{{scope.row.level | filterMarketing(that)}}</span>
                  </template>
               </el-table-column>
               <el-table-column label="分佣人姓名" prop="memberName" width="150"></el-table-column>
               <el-table-column label="分佣人电话" prop="mobile" width="150"></el-table-column>
               <el-table-column label="分佣金额" prop="awardAmount" width="100"></el-table-column>
               <el-table-column label="订单状态" width="100">
                  <template slot-scope="scope">
                     <span> {{ scope.row.orderStatus | filterOrderStatus(that) }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="分账状态" width="110">
                  <template slot-scope="scope">
                     <span>{{ scope.row.state | filterPaymentDetailed(that) }}</span>
                  </template>
               </el-table-column>
               <el-table-column label="到账时间" width="170">
                  <template slot-scope="scope" v-if="scope.row.accountTime">
                     <el-row>{{scope.row.accountTime}}</el-row>
                  </template>
               </el-table-column>
<!--               <el-table-column label="操作" width="200" fixed="right">-->
<!--                  <template slot-scope="scope">-->
<!--&lt;!&ndash;                     <el-button type="text" v-if="scope.row.state === 'FAIL'">&ndash;&gt;-->
<!--&lt;!&ndash;                        <el-link type="primary" @click="handleAccount(false,scope.row)">重新分账</el-link>&ndash;&gt;-->
<!--&lt;!&ndash;                     </el-button>&ndash;&gt;-->
<!--                  </template>-->
<!--               </el-table-column>-->
            </el-table>
         </el-row>
         <div slot="footer" class="dialog-footer" style="text-align: center;">
            <el-button class="bg-gradient" @click="handleClose">关闭</el-button>
         </div>
      </el-dialog>
   </section>
</template>

<script>
import { urlObj , business } from '@/api/interface'
import { getDict , exportFile } from '@/common/js/common'
import {reconciliation} from "@/api/interface/business";
import { mapState } from "vuex";
export default {
   name: "split_order_list",
   data(){
     return{
        that:this,
        tabState:'order',         //tab状态
        orderNumber:"",       //订单编号
        orderState:"",        //订单状态
        beginTime:"",      //支付开始时间
        endTime:"",        //支付结束时间
        deptPerson:"",        //分佣人
        storeId:'',
       selectshow:false,
        orderType:"",         //订单类型
        state:"",   //分账状态
        deptLevel:"",          //分佣类型
        arrivalBeginTime:"",  //到账开始时间
        arrivalEndTime:"",    //到账结束时间

        loading:true,         // 表格加载效果
        tableData: [],        // 表格数据
        isShow:"",            // 弹窗是否显示
        rowData:'',

        detailedLoading:true, // 表格加载效果
        detailedTable: [],    // 表格数据
        total: 0,             // 用户列表总条目数
        page: 1,              // 当前页 默认第一页
        limit: 0,             // 每页显示数
        pageState:true,

        Account_confirm : '确定重新发起分账请求？',
        Account_success : "重新分账操作成功",
        Account_cancel : "已取消重新分账",
     }
   },
   computed: {
      ...mapState(['hotelInfo','dictData'])
   },
   mounted() {
      sessionStorage.removeItem('dictData') // 移除目的为当缓存中已存在order-status，那order-status@splitOrderList并不会被触发
     if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
       this.selectshow = true
       this.storeId = this.hotelInfo.id
     }
      getDict(['order-type','order-status',"sub-account",'marketing-level','payment-state','order-status@splitOrderList'],true,
            {"order-status@splitOrderList":['ALREADY_EVALUATE','CANCEL','CLOSED_TRANSACTION',
              'HAVE_PAID_ABNORMAL','HAVE_TO_RETURN','ALREADY_EVALUATE','CLOSED_TRANSACTION',
               'PENDING_RECEIPT','PREPAYMENT_FAIL','STAY_EVALUATE','SUCCESSFUL_TRADE','TO_BE_SHIPPED',
               ]})
      this.limit = sessionStorage.getItem('pageSize') * 1
      this.getList()

   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.getList()
            }
         },
         deep: true
      },
   },
   methods:{
     getDate(val){
       this.storeId = val
       console.log(val)
     },
      //tabs切换
      tabClick(tab) {
         this.storeId = ''
         if(tab.index == '0'){
            this.tabState = 'order'
         }else{
            this.tabState = 'detailed'
         }
         this.loading = true
         this.page =  1
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.resetEvent()
         this.getList()
      },
      //获取表格数据
      getList(row,index){
         let param = {
            hotelId:this.hotelInfo.id,
            limit:this.limit,
            page:this.page,
            orderNo:this.orderNumber,  //订单编号
         }
         //分账明细
         if(this.tabState === "detailed" || row){
            const url = reconciliation.orderCommissionInfoDetail
            param.marketer = this.deptPerson                //分佣人
            param.orderStatus = this.orderState             //订单状态
            param.level = this.deptLevel                    //分佣类型
            param.payBeginTime = this.beginTime             //支付开始时间
            param.payEndTime = this.endTime                 //支付结束时间
            param.beginTime = this.arrivalBeginTime         //到账开始时间
            param.endTime = this.arrivalEndTime             //到账结束时间
            param.state = this.state // 分账状态
            if(row) {
               param.orderNo = row.orderNo
               // if(row === 'handleAccount'){ 被注释掉的 重新分账模块
               //    param.state = this.state  //分账状态
               // }
            }
           if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
             param.hotelId = this.storeId
             param.storeId = this.hotelInfo.storeId
           }
            this.$axios.post(url,param).then(res => {
               if (res.success) {
                  if(!row){
                     this.pageState = true
                     this.loading = false
                     this.tableData = res.records
                     this.total = res.total
                  }else{
                     this.detailedLoading = false
                     this.detailedTable = res.records
                     if(row !== 'handleAccount'){
                        this.rowData = row
                        this.detailedTable.forEach(item=>{
                           item.checkIndex = index+1  //弹窗表格序号
                        })
                     }
                  }
               }
            })
         }else{
            //分账订单
            const url = reconciliation.getShareTheMoneyList
            param.orderType = this.orderType          //订单类型
            param.subAccountState = this.state       //分账状态
            param.orderStatus = this.orderState     //订单状态
            param.beginTime = this.beginTime       //支付开始时间
            param.endTime = this.endTime           //支付结束时间
           if (JSON.parse(sessionStorage.getItem('userInfo')).accountType == 'STORE'){
              param.hotelId = this.storeId
             param.storeId = this.hotelInfo.storeId
           }
            this.$axios.get(url,param).then(res => {
               if (res.success) {
                  this.pageState = true
                  this.loading = false
                  this.tableData = res.records
                  this.total = res.total
               }
            })
         }
      },
      // 搜索
      queryEvent(){
         if(this.page != 1) this.pageState = false
         this.page =  1
         this.getList()
      },
      // 重置
      resetEvent(){
         this.orderNumber = ""
         this.orderState = ""
         this.beginTime = ""
         this.endTime = ""
         this.deptPerson = ''
         this.storeId = ''
         this.orderType = ""
         this.state = ""
         this.deptLevel=""
         this.arrivalBeginTime=""
         this.arrivalEndTime=""
         this.limit = sessionStorage.getItem('pageSize') * 1
      },
      //导出事件
      exportEvent(){
         let param = {
            hotelId:this.hotelInfo.id,
            limit:this.limit,
            page:this.page,
            orderNo:this.orderNumber,  //订单编号
         }
         let urlObject = ''
         if(this.tabState === 'order'){
            urlObject = reconciliation.shareTheMoneyListExcel
            param.orderType = this.orderType          //订单类型
            param.subAccountState = this.state       //分账状态
            param.orderStatus = this.orderState     //订单状态
            param.beginTime = this.beginTime       //支付开始时间
            param.endTime = this.endTime           //支付结束时间
         }else{
            urlObject = reconciliation.orderCommissionInfoDetailExcel
            param.marketer = this.deptPerson                //分佣人
            param.orderStatus = this.orderState             //订单状态
            param.state = this.state                        //分账状态
            param.level = this.deptLevel                    //分佣类型
            param.payBeginTime = this.beginTime             //支付开始时间
            param.payEndTime = this.endTime                 //支付结束时间
            param.beginTime = this.arrivalBeginTime         //到账开始时间
            param.endTime = this.arrivalEndTime             //到账结束时间
         }
         const url = urlObject
         this.$axios.get(url, param, {}, 'blob').then(res => {
            exportFile(res, this.tabState === 'order' ? '订单明细' : '分账明细')
         })
      },
      //查看详情
      handleCheck(row,index){
         this.isShow = true
         this.detailedLoading = true
         this.detailedTable = []
         this.getList(row,index)
      },
      // 关闭弹窗
      handleClose() {
         this.isShow = false
      },
      //重新分账
      // handleAccount(bool,row){
      //    this.$confirm(this.Account_confirm, '提示', {
      //       confirmButtonText: '确定',
      //       cancelButtonText: '取消',
      //       type: 'warning'
      //    }).then(() => {
      //          const url = reconciliation.anewProfitsharing+`/${row.id}`
      //          this.$axios.get(url,{}).then(res => {
      //             if (res.success) {
      //                this.$message({
      //                   type: 'success',
      //                   message: this.Account_success
      //                });
      //                this.getList('handleAccount')
      //             }
      //          })
      //    }).catch(() => {
      //       this.$message({
      //          type: 'info',
      //          message: this.Account_cancel
      //       });
      //    });
      // },
      // 改变每页数
      pageChange(num) {
         this.limit = num
         this.getList()
      },
      // 改变当前页
      handlePaging(num) {
         console.log(num,'num')
         this.page = num
         this.getList()
      },
   },
      filters: {
      filterOrderType(val,that) {
         if(val === 'E_BOOKING') return "客房"
         if (val && that.dictData['order-type']) return that.dictData['order-type'][val] || val
      },
      //订单状态
      filterOrderStatus(val,that){
         if(val === 'CONFIRM') return '已确认'
         if (val && that.dictData['order-status']) return that.dictData['order-status'][val] || val
      },
      // 订单分账状态
      filterPaymentOrder(val,that){
         if (val && that.dictData['sub-account']) return that.dictData['sub-account'][val] || val
      },
      // 明细分账状态
      filterPaymentDetailed(val,that){
         if (val && that.dictData['payment-state']) return that.dictData['payment-state'][val] || val
      },
      filterMarketing(val,that){
         if (val && that.dictData['marketing-level']) return that.dictData['marketing-level'][val] || val
      }
   }
}
</script>

<style scoped lang="scss">
.splitOrder-box{
   .tab-box{
      ::v-deep .el-tabs__header {
         margin: 0 0 31px;
         border-bottom: 1px solid #E3E4E5;
         .el-tabs__item{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #2D2D2D;
         }
         .is-active{
            font-size: 18px;
            font-weight: bold;
            color: #2577E3;
         }
      }
   }
   .split-dialog-box{
      margin-bottom: 30px;
      ul{
         list-style: none;
         li{
            width: 200px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
         }
         li:first-child{
            width: 280px;
         }
      }
   }
}
</style>
